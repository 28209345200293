<template>
  <block
    component-name="block-launcher-cta"
    body-class="grid grid-cols-12 gap-ui"
  >
    <template v-slot:default="blockData">
      <card-cta
        :overline="$attrs.data.body.overline"
        :title="$attrs.data.body.title"
        :text="$attrs.data.body.text"
        :button="$attrs.data.body.button"
        :asset="{
          ...$attrs.data.body.asset,
          sizes: {
            base: 600,
            phablet: 950,
            tablet: 500,
            laptop: 500,
            desktop: 650,
            extdesktop: 900,
          },
          loading: blockData?.loading || 'auto',
        }"
        class="col-span-full laptop:col-start-2 laptop:col-end-12 w-full"
      />
    </template>
  </block>
</template>
